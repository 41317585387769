<i18n>
{
    "en": {
        "title": "Forgot your login code?",
        "incompatibleBrowser": "If you have problems signing-in, please try again using Chrome browser.",
        "email": "Email",
        "loginCodeLabel": "Login Code",
        "renewButton": "Get new login code",
        "renewButtonComplete": "Complete",
        "renewComplete": "Your new login code is shown above. Please use this login code to log back into the game.",
        "logout": "Sign out",
        "notLoggedIn": "Failed to load Google account information. Please refresh the page and sign in again.",
        "serverError": "A server error has occurred. Please try again later."
    },
    "ko": {
        "title": "연동 코드를 잊어버리셨나요?",
        "incompatibleBrowser": "구글 로그인 오류가 발생하는 경우 크롬 브라우저에서 다시 시도해 주세요.",
        "email": "이메일",
        "loginCodeLabel": "연동 코드",
        "renewButton": "새 연동코드 발급",
        "renewButtonComplete": "신청 완료",
        "renewComplete": "연동코드가 재발급 되었습니다. 위에 표시된 연동코드를 사용하여 게임에 다시 로그인 해 주세요.",
        "logout": "로그아웃",
        "notLoggedIn": "로그인 데이터를 확인하지 못했습니다. 페이지를 새로고침 후 다시 로그인 해 주세요.",
        "serverError": "서버 오류가 발생하였습니다. 점검 등의 사유로 실패할 수 있으니 잠시 후 다시 시도해 주세요."
    }
}
</i18n>

<template>
    <div style="width:100%;height:100%;color:white">
        <div class="backgroundImageContainer">
            <!-- <v-img class="backgroundImage" transition="image-fade" :src="require('../assets/rhythmiz/Iz_liveLoading_Background-min.jpeg')" lazy-src="https://firebasestorage.googleapis.com/v0/b/iz-one.appspot.com/o/rhythmiz%2FIz_liveLoading_Background.jpeg?alt=media&token=ddb6a0c0-eb09-49c7-b578-19960c58748c"></v-img> -->
            <v-img class="backgroundImage" :src="require('../assets/rhythmiz/Iz_liveLoading_Background-min.jpeg')"></v-img>
        </div>
        <v-container style="height:100%" class="container">
            <v-row>
                <v-col align="right">
                    <v-icon color="white" style="display:inline-block;margin-bottom: 6px; margin-right:10px;">mdi-web</v-icon>
                    <v-select style="width:150px;display: inline-block;" color="white" v-model="locale" :items="locales" item-text="lang" item-value="code" single-line solo dense/>
                </v-col>
            </v-row>
            <v-row class="fill-height">
                <v-col cols="12" class="main-box">
                    <h2 style="margin-bottom:20px;margin-top:-150px">{{ $t("title") }}</h2>
                    <div v-show="!this.user.loggedIn">
                        <div id="firebase-auth-container"></div>
                        <span style="font-size:10pt;color:lightgray">{{ $t("incompatibleBrowser") }}</span>
                    </div>
                    <div class="logged-in" v-if="this.user.loggedIn">
                        <span>{{ $t("email") }}</span>
                        <h3>{{ this.user.data.email }}</h3>
                        <div v-show="this.locale == 'en' && this.code.length == 0">
                            <br/>
                            <b>Please note:</b>
                            <ul>
                                <li>This will only work if you have already created an account within the app.</li>
                                <li>Once you create a new login code, your previous login code will be revoked.</li>
                                <li>All devices logged in with this account will be logged out.</li>
                            </ul>
                        </div>
                        <div v-show="this.locale == 'ko' && this.code.length == 0">
                            <br/>
                            <b>참고사항</b>
                            <ul>
                                <li>앱에서 Rhythm*IZ 계정을 이미 만든 경우에만 새 연동코드를 생성할 수 있습니다.</li>
                                <li>연동코드를 새로 발급받을 경우 기존 연동코드는 사용이 불가능하게 됩니다.</li>
                                <li>재발급시 이 계정으로 로그인 된 모든 기기에서 로그아웃 됩니다.</li>
                            </ul>
                        </div>
                        <v-btn @click="renew" :disabled="this.applyButtonDisabled" class="rhythmiz-button" style="margin-top:20px;">
                        {{ applyButtonText }}&nbsp;<v-progress-circular v-show="this.applyLoading" width="2" size="20" color="white" indeterminate></v-progress-circular>
                        </v-btn>
                        <div style="margin-top:20px" v-show="this.code.length > 0">
                            <div>{{ $t("loginCodeLabel") }}</div>
                            <span>{{ this.code }}</span>
                            <div><br/>
                                {{ $t("renewComplete") }}
                                <br/>
                            </div>
                        </div>
                        <h4 style="margin-left:auto;margin-top:12px;cursor:pointer;" @click="logout">{{ $t("logout") }}</h4>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

  let firebaseui = require('firebaseui');
  import 'firebaseui/dist/firebaseui.css'
  import { getAuth, signOut } from "firebase/auth"
  import { GoogleAuthProvider } from "firebase/auth";
  import { mapGetters } from 'vuex';
  

  const auth = getAuth();
  const ui = new firebaseui.auth.AuthUI(auth);

  const axios = require("axios").default;

  export default {
    name: 'RhythmIZ',
    data(){
        return {
            applyLoading: false,
            applyButtonDisabled:false,
            code:"",
            alreadyExists:false,
            locale: this.$i18n.locale,
            locales: [
                { lang: "English", code: "en" },
                { lang: "한국어", code: "ko" }
            ]
        }
    },

    computed:{
        applyButtonText(){
            return this.$t("renewButton")
        },
        ...mapGetters({
            user: "user"
        })
    },

    watch: {
        locale(val){
            this.$i18n.locale = val;
        }
    },

    mounted(){
        if(this.$i18n.locale.length >= 2){
            let locale = this.$i18n.locale.substring(0, 2);
            if(locale == "en" || locale == "ko"){
                this.locale = locale;
            }else{
                this.locale = "en";
            }
        }else{
            this.locale = "en";
        }
        ui.start("#firebase-auth-container", {
            signInOptions: [
                GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
            ],
            callbacks: {
                signInSuccessWithAuthResult(){
                    return false;
                }
            }
            
        });
    },

    methods: {
        logout(){
            signOut(auth).then(() => {
                this.code = "";
                ui.start("#firebase-auth-container", {
                    signInOptions: [
                        GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
                    ],
                    callbacks: {
                        signInSuccessWithAuthResult(){
                            return false;
                        }
                    }
                    
                });
            });
        },

        renew(){
            if(!this.user.loggedIn){
                alert(this.$t("notLoggedIn"));
            }else{
                let form = new FormData();
                form.append("token", this.user.data.accessToken);
                this.applyButtonDisabled = true;
                this.applyLoading = true;
                axios.post("https://rhythmiz.wiz-one.space/reset_password", form).then((response) => {
                    this.applyLoading = false;
                    this.applyButtonText = this.$t("renewButtonComplete");
                    if(response.data.success){
                        this.code = response.data.password;
                    }else{
                        alert(response.data.message);
                        this.applyButtonDisabled = false;
                    }
                }).catch(() => {
                    this.applyButtonDisabled = false;
                    alert(this.$t("serverError"));
                });
            }
        }
    }
  }
</script>

<style>
    a{
        color: pink !important;
    }
</style>

<style scoped>
.rhythmiz-button{
    background-color:#E1458B !important;
    color:white !important;
    font-weight:bold !important;
    font-size:12pt !important;
}

.backgroundImageContainer{
    z-index:0;
    position:absolute;
    width:100%;
    height:100%;
    object-fit: cover;
}

.backgroundImage{
    object-fit:cover;
    width:100%;
    height:100%;
    /* max-wdth:100%;
    height:100%; */
}

.container{
    overflow:scroll;
}

/* .container{
    display:flex;
    flex-direction:column;
    justify-content: center;
} */

.main-box{
    z-index:10;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100%;
}

.container *{
    z-index:10;
}

.logged-in{
    display:flex;
    flex-direction: column;
    background-color:rgba(255, 255, 255, 0.2);
    width:400px;
    padding:20px;
}
</style>